import { useState } from "react";
import yellow from "../images/results/Yellow.svg";
import "../stylesheets/results-marketing.scss";

const AttributeKnowledge = ({ attributeData }) => {
  const [showAllKnowl, setShowAllKnowl] = useState(false);

  function handleKnowlClick() {
    setShowAllKnowl((showAllKnowl) => {
      return !showAllKnowl;
    });
  }

  const allKnowledge = attributeData.data[1]?.knowledge || [];
  const initiallyVisibleKnowledge = allKnowledge.slice(0, -3);
  const additionalKnowledge = allKnowledge.slice(-3);

  return (
    <section className="attribute-category">
      <div className="attribute-type-container">
        <img className="single-puzzle" alt="" src={yellow} />
        <span className="attribute-type">Knowledge</span>
      </div>
      <ul className="attribute-list knowledge">
        {initiallyVisibleKnowledge.map((knowledge, index) => (
          <li key={index}>
            <span className="attribute-name">
              {knowledge.attribute}
              <span className="attribute-avg"> AVG: {knowledge.average}</span>
            </span>
          </li>
        ))}
        {showAllKnowl && (
          <>
            {additionalKnowledge.map((knowledge, index) => (
              <li key={index}>
                <span className="attribute-name">
                  {knowledge.attribute}
                  <span className="attribute-avg"> AVG: {knowledge.average}</span>
                </span>
              </li>
            ))}
          </>
        )}
      </ul>
      {showAllKnowl ? (
        <button className="less" onClick={handleKnowlClick}>
          Less
        </button>
      ) : (
        <button className="view-all" onClick={handleKnowlClick}>
          View All
        </button>
      )}
    </section>
  );
};

export default AttributeKnowledge;
